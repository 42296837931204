import styles from './index.module.scss'
import cm from 'classnames'
import Link from 'next/link'
import omit from 'lodash-es/omit'
import { forwardRef } from 'react'

const Button = (props, ref) => {
  const { className = '', link = false, hover = '' } = props

  const btnClass = cm(styles.btn, styles[hover], className)
  if (link) {
    return (
      <Link
        className={btnClass}
        {...omit(props, ['children', 'link', 'className'])}
        data-clipboard-text={props['data-clipboard-text']}>
        {props.children}
      </Link>
    )
  }

  return (
    <button className={btnClass} onClick={props.onClick} ref={ref} data-clipboard-text={props['data-clipboard-text']}>
      {props.children}
    </button>
  )
}

export default forwardRef(Button)
