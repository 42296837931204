import styles from './index.module.scss'
import { useRouter } from 'next/router'
import Link from 'next/link'
import cm from 'classnames'
import { useRecoilValue } from 'recoil'
import { userInfo } from '@/store'
import { useShowLogin } from '@/utils/hooks'
import Nav from './nav'
import { memo } from 'react'
import dynamic from 'next/dynamic'
import { useState } from 'react'
const SearchPhone = dynamic(() => import('@c/modal/search-phone'))
const Index = ({ className, style }) => {
  const user = useRecoilValue(userInfo)
  const router = useRouter()
  const setShowLogin = useShowLogin()
  const [showSearch, setShowSearch] = useState(false)
  //登录
  const loginIn = e => {
    e.stopPropagation()
    if (user?.isLogin) {
      router.push('/userinfo')
    } else {
      setShowLogin(true)
    }
  }

  return (
    <>
      <header className={cm(styles.header, className, 'hideWhenPc')} style={{ ...style }}>
        <Link href="/" className={styles.left}>
          {router.pathname === '/' ? (
            <h1>
              <img loading="lazy" src="/api/images/ico.png" alt="华视界" className={styles.img} />
            </h1>
          ) : (
            <h1>
              <img loading="lazy" src="/api/images/ico.png" alt="华视界" className={styles.img} />
            </h1>
          )}
        </Link>
        <div className={styles.right}>
          <>
            <img
              loading="lazy"
              className={styles.searchIcon}
              src="/api/images/h-search"
              alt="搜索"
              style={{ width: '30px', height: '30px' }}
              onClick={() => setShowSearch(true)}
            />
          </>
          {typeof user?.token !== 'undefined' && user.isLogin && (
            <Link href="/vip">
              <img loading="lazy" alt="vip" className={cm(styles.searchIcon)} src="/api/images/vip1.png" />
            </Link>
          )}
          <Link href="/download">
            <img loading="lazy" className={styles.searchIcon} src="/api/images/down" alt="下载" />
          </Link>
          <Link href="https://t.me/+CKkE1hC7_3dlYTk1" rel="nofollow" target="_blank">
            <img loading="lazy" className={styles.searchIcon} src="/api/images/join" alt="加入社群" />
          </Link>
          {user.isLogin ? (
            <img
              loading="lazy"
              alt="用户头像"
              className={styles.rightUser}
              onClick={loginIn}
              src={'/api/images/user_head_portrait'}
              style={{ width: '25px', height: '25px' }}
            />
          ) : (
            <img
              loading="lazy"
              alt="用户头像"
              className={styles.rightUser}
              onClick={loginIn}
              src={'/api/images/user'}
            />
          )}

          <Nav />
        </div>
        {showSearch && <SearchPhone onClose={() => setShowSearch(false)} />}
      </header>

      {/* <div className={styles.empty}>

    </div> */}
    </>
  )
}

export default memo(Index)
