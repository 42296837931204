import styles from './index.module.scss'
import { useRecoilValue } from 'recoil'
import { userInfo } from '@/store'
import { useUserTab, useShowLogOut, useShowLogin } from '@/utils/hooks'
import cm from 'classnames'
import Button from '../../../button'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import { useRouter } from 'next/router'

const Index = () => {
  const setShowLogOut = useShowLogOut()
  const setShowLoginIn = useShowLogin()
  const user = useRecoilValue(userInfo)
  const tabArr = useUserTab()
  const router = useRouter()
  const quanyiMemo = useMemo(() => {
    const expire = dayjs(user.expireTime * 1000)
    const lastTime = expire.diff(dayjs(new Date()), 'day')

    if (lastTime > 0 && lastTime <= 2) {
      return {
        img: (
          <div className={cm(styles.vipImg, styles.vipActive)}>
            <img loading="lazy" className={styles.vipLogo} src="/api/images/vip-active" alt="vip" />
          </div>
        ),
        time: <p className={cm(styles.pActive, 'textEllipsis')}>VIP权益还有{lastTime + 1}天到期</p>,
        toVip: (
          <Button hover="opacity" link href="/vip" className={styles.toVip}>
            立即续费
          </Button>
        )
      }
    } else if (lastTime > 2) {
      return {
        img: (
          <div className={cm(styles.vipImg, styles.vipActive)}>
            <img loading="lazy" className={styles.vipLogo} src="/api/images/vip-active" alt="vip" />
          </div>
        ),
        time: <p className={cm(styles.pActive, 'textEllipsis')}>VIP权益至{expire.format('YYYY/MM/DD')}</p>
      }
    } else {
      if (!user.expireTime) {
        return {
          img: <div className={styles.vipImg}></div>,
          time: null,
          toVip: (
            <Button hover="opacity" link href="/vip" className={styles.toVip}>
              开通VIP
            </Button>
          )
        }
      }
      return {
        img: (
          <div className={cm(styles.vipImg, styles.vipInActive)}>
            <img loading="lazy" className={styles.vipLogo} src="/api/images/vip-inactive" alt="vip" />
          </div>
        ),
        time: <p className={styles.pInActive}>VIP权益已过期</p>,
        toVip: (
          <Button hover="opacity" link href="/vip" className={styles.toVip}>
            开通VIP
          </Button>
        )
      }
    }
  }, [user])

  return (
    <>
      {/* {user?.isLogin ? (
        <div className={styles.loginUser}>
          <img loading="lazy" alt="用户" src="/api/images/user3" className={styles.head} onClick={tabArr[0].event} />
          <span className={cm(styles.name, 'textEllipsis')}>{user?.email}</span>
          <div className={styles.loginMenu}>
            <div className={styles.userInfo}>
              {quanyiMemo.img}
              <div className={cm(styles.vipDate,quanyiMemo.toVip ? styles.ellipsis : styles.full)}>
                <p className={cm(styles.email, 'textEllipsis')}>{user?.email}</p>
                {quanyiMemo.time}
              </div>
              {quanyiMemo.toVip}
            </div>
            <div className={styles.userTabMenu}>
              {tabArr.map((item, index) => {
                return (
                  <div className={styles.tabMenuItems} key={index} onClick={item.event}>
                    <img loading="lazy" src={item.icon} alt={item.label} />
                    <span>{item.label}</span>
                  </div>
                )
              })}
            </div>
            <div className={styles.exitText} onClick={setShowLogOut}>
              退出登录
            </div>
          </div>
        </div>
      ) : null} */}

      {user?.isLogin ? (
        <Button hover="scaleImg" className={cm(styles.LoginUser)}>
          <img
            onClick={() => router.push('/userinfo')}
            loading="lazy"
            className={styles.icon}
            src="/api/images/user_head_portrait.png"
            title="登录"
            alt="登录"
          />
          <div className={styles.dialog}>
            {/* <span >{user?.email}</span> */}
            <div className={styles.user}>
              <img loading="lazy" className={styles.avatar} src="/api/images/user" title="登录" alt="登录" />

              <div className={styles.info}>
                <div className={styles.email}>{user?.email} </div>
                <div className={styles.time}> {quanyiMemo.time} </div>
              </div>
            </div>
            <div className={styles.line}></div>

            {tabArr.map((item, index) => {
              return (
                <div className={styles.list} key={index} onClick={() => router.push(`/${item.key}`)}>
                  <div className={styles.left}>
                    <img loading="lazy" src={item.icon} alt={item.label} className={styles.icon} />
                    <div className={styles.content}>{item.label}</div>
                  </div>
                  <div className={styles.right}>
                    <img loading="lazy" src="/api/images/ar.png" alt={item.label} className={styles.iconx} />
                  </div>
                </div>

                // <div className={styles.tabMenuItems} key={index} onClick={item.event}>
                //   <img loading="lazy" src={item.icon} alt={item.label} />
                //   <span>{item.label}</span>
                // </div>
              )
            })}

            <div className={styles.exitText} onClick={setShowLogOut}>
              退出登录
            </div>
          </div>
        </Button>
      ) : (
        <Button hover="scaleImg" className={cm(styles.NotLoginUser)}>
          <img loading="lazy" className={styles.icon} src="/api/images/user" title="登录" alt="登录" />
          {/* <div>我的</div> */}
          <div className={styles.dialog}>
            <div className={styles.tv}>
              <div className={styles.title}>欢迎登录，登陆后畅想追剧体验</div>
              <div className={styles.btn} onClick={() => setShowLoginIn(true)}>
                立即登录
              </div>
            </div>
          </div>
        </Button>
      )}
    </>
  )
}

export default Index
