import { useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import raf from 'raf-es'
import EventBus from './emitter'
import { getUserInfo } from '@/api/login'
import { userInfo } from '@/store'
import dayjs from 'dayjs'
import { useRecoilState } from 'recoil'

// 更新用户信息
export const useSetUserInfo = () => {
  const [user, setUserInfo] = useRecoilState(userInfo)
  const getUsers = async token => {
    if (!token) {
      setUserInfo({
        token: '',
        isLogin: false
      })
      return
    }
    const res = await getUserInfo(token)

    if (res.code) {
      setUserInfo({
        token: '',
        isLogin: false
      })

      localStorage.removeItem('token')
      return
    }
    const lastTime = dayjs(res.data?.expireTime * 1000).diff(new Date())
    setUserInfo({
      ...(res?.data || {}),
      isLogin: true,
      token,
      isVip: lastTime > 0
    })
  }
  return [user, getUsers]
}

/**
 * 计算页面label排列数
 * */
export const useWindowWidth = () => {
  const [width, setWidth] = useState(8)
  useEffect(() => {
    setWidth(window.innerWidth)
    const resize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])
  let count = 6
  if (width >= 1617) {
    count = 6
  } else if (width >= 1313) {
    count = 5
  } else if (width >= 763) {
    count = 4
  } else if (width > 0) {
    count = 3
  }
  return {
    count
  }
}
//媒体查询
export const useMediaQuery = query => {
  const [matchs, setmatches] = useState(() => window.matchMedia(query).matches)
  useEffect(() => {
    const mediaQuery = window.matchMedia(query)
    const handleChange = e => setmatches(e.matches)
    mediaQuery.addEventListener('change', handleChange)
    return mediaQuery.removeEventListener('change', handleChange)
  }, [query])
  return matchs
}

/**
 * 计算页面演员数量
 * */
export const useActorWidth = () => {
  const [width, setWidth] = useState(9)
  useEffect(() => {
    setWidth(window.innerWidth)
    const resize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])
  let count = 8
  if (width >= 1704) {
    count = 8
  } else if (width >= 1496) {
    count = 7
  } else if (width >= 1288) {
    count = 6
  } else if (width >= 1080) {
    count = 5
  } else if (width >= 872) {
    count = 4
  } else if (width >= 664) {
    count = 3
  }
  return {
    count
  }
}

/**
 * 计算体育页面label排列数
 * */
export const useSportWindowWidth = () => {
  const [width, setWidth] = useState(9)
  useEffect(() => {
    setWidth(window.innerWidth)
    const resize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])
  let count = 9
  if (width >= 1590) {
    count = 9
  } else if (width >= 1440) {
    count = 8
  } else if (width >= 1290) {
    count = 7
  } else if (width >= 1140) {
    count = 6
  } else if (width >= 990) {
    count = 5
  } else if (0 < width < 990) {
    count = 4
  }
  return {
    count
  }
}

/**
 * 计算体育页面热门排列数
 * */
export const useHotWindowWidth = () => {
  const [width, setWidth] = useState(1)
  useEffect(() => {
    setWidth(window.innerWidth)
    const resize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])
  let count = 1
  if (width > 1464) {
    count = 4
  } else if (width > 1008) {
    count = 3
  } else if (width > 766) {
    count = 2
  }
  return {
    count
  }
}

/**
 * 下滑滚动监听hook
 * */
export const useScrollVisible = (space = 100) => {
  const [isVisible, setIsVisible] = useState(false)
  const [scrollProgress, setProgress] = useState(0)
  useEffect(() => {
    const windowWidth = window.innerWidth * 0.364583 * 0.6
    function handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      setIsVisible(scrollTop > space)
      const diff = scrollTop >= windowWidth ? 1 : scrollTop / windowWidth
      setProgress(diff)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [space])
  return {
    isVisible,
    scrollProgress
  }
}
// vip 页面下滑监听
export const useScrollVisibleVip = () => {
  const [showRight, setShowRight] = useState(false)
  const [rightNo, setRightNo] = useState(0)
  const [scrollTop, setScrollTop] = useState(0)
  useEffect(() => {
    function handleScroll() {
      const scrollTops = document.documentElement.scrollTop
      if (scrollTops > 790) {
        setShowRight(true)
      } else {
        setShowRight(false)
      }
      if (scrollTops > 790 && scrollTops <= 988) {
        setRightNo(0)
      } else if (scrollTops > 988 && scrollTops <= 1210) {
        setRightNo(1)
      } else if (scrollTops > 1210 && scrollTops <= 1460) {
        setRightNo(2)
      } else if (scrollTops > 1460 && scrollTops <= 1690) {
        setRightNo(3)
      } else if (scrollTops > 1690 && scrollTops <= 1920) {
        setRightNo(4)
      } else {
        setRightNo(5)
      }
      setScrollTop(scrollTops)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  return {
    rightNo,
    showRight,
    scrollTop
  }
}

/**
 * 存储页面滚动距离，用于还原用户行为数据
 * */
export const useScrollSessionStore = page => {
  const router = useRouter()

  useEffect(() => {
    function handleBeforeUnload() {
      const scrollTop = document.documentElement.scrollTop || window.pageYOffset || window.scrollY

      sessionStorage.setItem(page, scrollTop)
    }

    router.events.on('routeChangeStart', handleBeforeUnload)
    return () => {
      router.events.off('routeChangeStart', handleBeforeUnload)
    }
  }, [])
}

/**
 * 倒计时hooks
 * */
export const useCountDown = (initSeconds = 60) => {
  const [seconds, setSeconds] = useState(initSeconds)
  const [isRunning, setIsRunning] = useState(false)
  const timer = useRef(null)
  const clear = () => {
    if (!timer.current) return
    clearInterval(timer.current)
    timer.current = null
  }
  useEffect(() => {
    clear()
    if (isRunning) {
      timer.current = setInterval(() => {
        setSeconds(time => {
          let sec = time - 1
          if (sec < 0) {
            clearInterval(timer)
            setIsRunning(false)
            timer.current = null
            return initSeconds
          }
          return sec
        })
      }, 1000)
    }

    return () => clear()
  }, [isRunning, timer, initSeconds])

  const startCountDown = () => {
    setIsRunning(true)
  }
  const stopCountDown = () => {
    setIsRunning(false)
    setSeconds(initSeconds)
  }
  return [seconds, isRunning, startCountDown, stopCountDown]
}

/**
 * 封装raf hook
 * */
export const useRaf = (handle, cb) => {
  let animationId = null
  const cancelAnimation = () => {
    raf.cancel(animationId)
    animationId = null
  }

  const startAnimation = () => {
    const animate = () => {
      const isEnd = !handle?.()
      if (isEnd || !animationId) {
        setTimeout(cb, 16)
        cancelAnimation()
        return
      }
      animationId = raf(animate)
    }
    animationId = raf(animate)
  }
  return [startAnimation, cancelAnimation]
}

/**
 * 获取用户中心tab
 * */
export const useUserTab = cb => {
  const router = useRouter()
  const tabArr = [
    {
      key: 'history',
      icon: '/api/images/history_page',
      iconActive: '/api/images/history_page_on',
      event: () => {
        router.push('/history')
        cb?.()
      },
      label: '观看历史'
    },
    {
      key: 'collect',
      icon: '/api/images/collect_page',
      iconActive: '/api/images/collect_page_on',
      event: () => {
        router.push('/collect')
        cb?.()
      },
      label: '我的收藏'
    }
    // {
    //   key: 'zj',
    //   icon: '/api/images/selfbuild',
    //   iconActive: '/api/images/selfbuild_on',
    //   event: () => {
    //     router.push('/zj')
    //     cb?.()
    //   },
    //   label: '直播自建',
    //   anotherLabel: '直播自建'
    // }
  ]
  return tabArr
}

/**
 * 用户登录弹窗
 * */
export const useShowLogin = () => {
  return () => {
    EventBus.emit('openIn')
  }
}
/**
 * 邀请好友
 */
export const useInvite = () => {
  return () => {
    EventBus.emit('invite')
  }
}

export const useShowInfo = () => {
  return () => {
    EventBus.emit('showInfo')
  }
}

/**
 * 用户登出弹窗
 * */
export const useShowLogOut = () => {
  return () => {
    EventBus.emit('openLoginOut')
  }
}

/**
 * 套餐弹窗弹出
 * */
export const useVipDialog = () => {
  return index => {
    EventBus.emit('openVipDialog', false, index)
  }
}
/**
 * 处理sticky兼容性
 **/
export const useSolveStickyPower = () => {
  useEffect(() => {
    const StickyFill = require('stickyfill')()
    const els = document.querySelectorAll('.sticky')
    els.forEach(item => {
      StickyFill.add(item)
    })
  }, [])
}

/**
 * 单击双击事件hook
 * */
export const useIsolateDbClick = cbArr => {
  if (!Array.isArray(cbArr)) {
    throw new Error('params must be array')
  }
  cbArr.length = 2
  const [doubleClick, setDoubleClick] = useState(0)
  const dbClickTimer = useRef(null)
  const clear = () => {
    if (dbClickTimer.current) {
      clearTimeout(dbClickTimer.current)
      dbClickTimer.current = null
    }
  }
  useEffect(() => {
    return () => {
      clear()
    }
  }, [])
  return params => {
    setDoubleClick(prev => prev + 1)
    if (doubleClick === 0) {
      dbClickTimer.current = setTimeout(() => {
        cbArr[0]?.(params)
        setDoubleClick(0)
      }, 300) // 在指定的时间内判断是否双击事件
    } else if (doubleClick === 1) {
      cbArr[1]?.(params)
      clear()
      setDoubleClick(0)
    }
  }
}
