import request from '@/services/request'

// 获取直播所有数据
export const getChannels = async (options = {}) => {
  return await request.get('/web/v1/channels', {}, options)
}

// 获取节目单
export const getProgramList = async data => {
  return await request.get('/v3/api/programme/get', data)
}

// 获取源列表
export const getSourceList = async (id, options = {}) => {
  return await request.get(`/web/v1/zhibo/streams/${id}`, {}, options)
}

// 获取频道组同组频道
export const getGroupChannel = async (id, options = {}) => {
  return await request.get(`/web/v1/zhibo/channels/${id}`, {}, options)
}

// 获取直播描述/web/api/getLiveDesc
export const getLiveDesc = async (id, options = {}) => {
  return await request.get(`/web/v1/liveDesc/${id}`, {}, options)
}

// 获取视频url是否可用
export const canUsePlayUrl = async url => {
  return await request.get(url)
}

export const getLiveUrl = async url => {
  return await request.get(`/api/iptv/getPlayUrl?tid=gt&id=74`)
}